import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import CartContext from "../context/CartContext";

import { FaTrain, FaMapMarkerAlt } from "react-icons/fa";
import { IoHeartDislike } from "react-icons/io5";
import ImageSlider from "./image-slider";
import { Link } from "gatsby";
import CardPillTagSection from "./card-pill-tag-section";

const slugify = require("slugify");

const ShortlistPropertyCard = ({ property, index }) => {
	const {
		cart,
		onLocationChangeScroll,
		getDistanceFromLatLonInKm,
		OnAddToCart,
		OnRemoveFromCart,
		distancePoint,
		selectedLocation,
	} = React.useContext(CartContext);

	const {
		locationLatitude,
		locationLongitude,
		photosNew,
		name: propertyName,
		subwayStation1,
		subwayStation1DistanceKm,
		subwayStation1DurationMins,
		subwayStation2,
		subwayStation2DistanceKm,
		subwayStation2DurationMins,
		features,
	} = property;

	const KM_TO_MILE = 0.621371;

	var floors = [];
	if (property?.floorsAvailable !== null) {
		floors =
			property?.floorsAvailable
				?.sort((a, b) => parseFloat(a.rentPM) - parseFloat(b.rentPM))
				.slice() || [];
	}

	return (
		<div className="position-relative">
			<div
				className="py-3 position-absolute end-0 pe-4 clickable-heart"
				style={{ zIndex: 1 }}
			>
				<IoHeartDislike
					onClick={() => {
						OnRemoveFromCart(property);
					}}
					style={{ cursor: "pointer" }}
					className="fs-6 text-red"
				/>
				<span
					onClick={() => {
						OnRemoveFromCart(property);
					}}
					style={{ fontSize: "90%", cursor: "pointer" }}
				>
					{" "}
					Remove
				</span>
			</div>
			<Link to={`/listings/${slugify(propertyName)}`}>
				<div
					className="bg-white mb-5 px-0 mx-0  text-black"
					onMouseEnter={() => {
						onLocationChangeScroll(locationLatitude, locationLongitude);
					}}
				>
					<Row
						className="px-0 mx-0 g-0 h-100 "
						style={{ borderBottom: "1px solid #f5f5f5" }}
					>
						<Col lg={6}>
							<ImageSlider height="410px" imageDataArr={photosNew} />
						</Col>
						<Col
							className="ps-3 pe-3 pt-3 position-relative"
							style={{ minHeight: "100%" }}
							lg={6}
						>
							<div
								style={{ fontSize: "90%" }}
								className="d-flex align-items-center text-med-grey mb-3 pt-2"
							>
								<FaMapMarkerAlt className="me-2" />
								<p className="d-inline-block pb-0 mb-0 ">
									{selectedLocation.split(",")[0] || "London"} -{" "}
									{(
										getDistanceFromLatLonInKm(
											distancePoint.latitude,
											distancePoint.longitude,
											locationLatitude,
											locationLongitude
										) * KM_TO_MILE
									).toFixed(2)}{" "}
									mi
								</p>
							</div>
							<h3 className="fs-4 mb-3">{propertyName}</h3>{" "}
							{/*
              <div className="mb-4 ps-2 border-left border-start border-primary border-3">
                <p className="mb-1">Private offices from</p>
                <h5 className="mt-1">£695pcm</h5>
              </div> */}
							<div
								style={{ overflow: "hidden" }}
								className="position-absolute d-none d-lg-block start-0 bottom-0 w-100 py-3"
							>
								<Row className="px-3 text-black">
									<Col xs={12}>
										{property?.type === "Serviced Office" && (
											<>
												<p className="mb-0">Private offices from</p>
												<h5 className="mt-1 Roboto-Medium fs-4">
													£{property?.rentPM.toLocaleString("en-UK")}
													pcm
												</h5>
											</>
										)}

										{property?.type === "Managed Office" && (
											<>
												<p className="mb-0">Private floors from</p>
												<h5 className="mt-1 Roboto-Medium fs-4">
													£{floors[0]?.rentPM.toLocaleString("en-GB")}pcm
												</h5>
											</>
										)}
									</Col>
								</Row>
								<Row className="mt-2">
									<hr
										style={{ opacity: 1, color: "#042955" }}
										className="text-primary"
									/>
								</Row>
								<Row className="px-4">
									<CardPillTagSection features={property?.features} />
								</Row>
							</div>
							<div className=" d-lg-none ">
								<Row className="px-3 text-black">
									<Col xs={12}>
										{property?.type === "Serviced Office" && (
											<>
												<p className="mb-0">Private offices from</p>
												<h5 className="mt-1 Roboto-Medium fs-4">
													£{property?.rentPM.toLocaleString("en-UK")}
													pcm
												</h5>
											</>
										)}

										{property?.type === "Managed Office" && (
											<>
												<p className="mb-0">Private floors from</p>
												<h5 className="mt-1 Roboto-Medium fs-4">
													£{floors[0]?.rentPM.toLocaleString("en-GB")}pcm
												</h5>
											</>
										)}
									</Col>
								</Row>
								<Row className="mt-2">
									<hr
										style={{ opacity: 1, color: "#042955" }}
										className="text-primary"
									/>
								</Row>
								<Row className="px-3">
									<CardPillTagSection features={property?.features} />
								</Row>
							</div>
						</Col>
					</Row>
				</div>
			</Link>
		</div>
	);
};

export default ShortlistPropertyCard;

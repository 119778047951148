import { ValidationError, useForm } from "@formspree/react";
import "moment/locale/en-gb";
import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";
import { v4 as uuidv4 } from "uuid";
import CartContext from "../context/CartContext";

function ContactForm() {
	const {
		cart,
		settingVal,
		numberOfPeople,
		budgetPerDesk,
		selectedLocation,
		budgetMinMax,
	} = React.useContext(CartContext);
	const [state, handleSubmit] = useForm("mvonyjbw");

	var propertyArray = [];
	var propertyIdArray = [];
	var providersArray = [];

	const [showNotes, setShowNotes] = useState(false);
	const [currentStep, setCurrentStep] = useState("one");

	const formGroupStyles = "mb-2 mb-xl-4";
	const formInputStyles = "p-xl-4 p-3 ps-xl-5 ps-3  border-0";

	if (cart !== []) {
		propertyArray = cart?.map((property, i) => property.name);
		propertyIdArray = cart?.map((property, i) => property.airtableId);
		cart?.forEach((property, i) => {
			if (providersArray.includes(property.provider)) {
				providersArray.push(`${property.provider}-${i}`);
			} else {
				providersArray.push(property.provider || "Unknown");
			}
		});
	} else {
		propertyArray = ["no properties selected"];
		propertyIdArray = ["no properties selected"];
		providersArray = ["no properties selected"];
	}
	const uniqueId = uuidv4();

	const [proptiesList, setProptiesList] = useState([]);
	const [providersList, setProvidersList] = useState([]);
	const [proptiesIdList, setProptiesIdList] = useState([]);
	const [limiter, setLimiter] = useState(0);

	useEffect(() => {
		if (cart !== []) {
			setProptiesList(propertyArray);
			setProptiesIdList(propertyIdArray);
			setProvidersList(providersArray);
			console.log(providersArray);
		}
	}, []);

	useEffect(() => {
		const empty = [];

		if (state.succeeded) {
			settingVal(empty, "cart");
		}
	}, [state.succeeded]);

	if (state.succeeded) {
		return (
			<div>
				{" "}
				<Form className="w-100" onSubmit={handleSubmit}>
					<Form.Group
						className={`${formGroupStyles}`}
						controlId="Date and time"
					>
						<Form.Label>Date and time</Form.Label>
						{/* <Form.Control
					required
					name="Date and time"
					//   placeholder="Date and time"
					type="datetime-local"
					//   onFocus={(e) => (e.currentTarget.type = "datetime-local")}
					//   onBlur={(e) => (e.currentTarget.type = "text")}
					className={`${formInputStyles}`}
				/> */}
						<Datetime
							dateFormat="Do MMM YYYY"
							timeFormat="HH:mm"
							inputProps={{
								placeholder: "Date and time",
								required: true,
								name: "Date and time",
								className: `${formInputStyles} form.control w-100`,
							}}
							timeConstraints={{ minutes: { step: 30 } }}
						/>
					</Form.Group>
					<ValidationError
						prefix="Date and time"
						field="Date and time"
						errors={state.errors}
					/>

					<Form.Group className={`${formGroupStyles}`} controlId="Name">
						<Form.Label>Name</Form.Label>
						<Form.Control
							required
							name="Name"
							placeholder="Joe Bloggs"
							type="text"
							className={`${formInputStyles}`}
						/>
					</Form.Group>
					<ValidationError prefix="Name" field="Name" errors={state.errors} />

					<Form.Group className={`${formGroupStyles}`} controlId="Company">
						<Form.Label>Company</Form.Label>

						<Form.Control
							required
							name="Company"
							placeholder="XYZ Co"
							type="text"
							className={`${formInputStyles}`}
						/>
					</Form.Group>
					<ValidationError
						prefix="Company"
						field="Company"
						errors={state.errors}
					/>

					<Form.Group className={`${formGroupStyles}`} controlId="Move in date">
						<Form.Label>Move in date</Form.Label>
						<Datetime
							dateFormat="Do MMM YYYY"
							timeFormat={false}
							inputProps={{
								placeholder: "Move in date",
								required: true,
								name: "Move in date",
								className: `${formInputStyles} form.control w-100`,
							}}
						/>
					</Form.Group>
					<ValidationError
						prefix="Move in date"
						field="Move in date"
						errors={state.errors}
					/>

					<Form.Group
						className={`${formGroupStyles}`}
						controlId="Telephone number"
					>
						<Form.Label>Telephone number</Form.Label>

						<Form.Control
							required
							type="tel"
							name="Telephone number"
							placeholder="07123 456789"
							className={`${formInputStyles}`}
						/>
					</Form.Group>

					<ValidationError
						prefix="Telephone number"
						field="Telephone number"
						errors={state.errors}
					/>
					<Form.Group
						className={`${formGroupStyles}`}
						controlId="E-mail address"
					>
						<Form.Label>Email address</Form.Label>

						<Form.Control
							required
							name="E-mail address"
							type="E-mail address"
							placeholder="joe@xyz.com"
							className={`${formInputStyles}`}
						/>
					</Form.Group>
					<ValidationError
						prefix="E-mail address"
						field="E-mail address"
						errors={state.errors}
					/>
					<Form.Group
						className={`${formGroupStyles} d-none d-xl-block`}
						controlId="Notes"
					>
						<Form.Label>Notes</Form.Label>
						<Form.Control
							required
							name="Notes"
							//   placeholder="Notes"
							as="textarea"
							rows={3}
							className={`${formInputStyles}`}
						/>
					</Form.Group>
					<ValidationError prefix="Notes" field="Notes" errors={state.errors} />
					{!showNotes && (
						<p
							style={{ cursor: "pointer" }}
							className="text-primary p-1 m-0 d-xl-none"
							onClick={() => setShowNotes(true)}
						>
							Add notes...{" "}
						</p>
					)}
					{showNotes && (
						<>
							<Form.Group
								className={`${formGroupStyles} d-xl-none`}
								controlId="Notes"
							>
								<Form.Label>Notes</Form.Label>
								<Form.Control
									required
									name="Notes"
									//   placeholder="Notes"
									as="textarea"
									rows={3}
									className={`${formInputStyles}`}
								/>
							</Form.Group>
							<ValidationError
								prefix="Notes"
								field="Notes"
								errors={state.errors}
							/>
						</>
					)}

					<Form.Group className="mb-3 d-none" controlId="properties">
						<Form.Control
							name="properties"
							placeholder="properties"
							value={proptiesList}
							as="textarea"
							rows={3}
						/>
					</Form.Group>

					<ValidationError
						prefix="properties"
						field="properties"
						errors={state.errors}
					/>
					<Form.Group className="mb-3 d-none " controlId="providers">
						<Form.Control
							name="providers"
							placeholder="providers"
							value={providersList}
							as="textarea"
							rows={3}
						/>
					</Form.Group>

					<ValidationError
						prefix="providers"
						field="providers"
						errors={state.errors}
					/>
					<Form.Group className="mb-3 d-none" controlId="propertyIds">
						<Form.Control
							name="propertyIds"
							placeholder="propertyIds"
							value={proptiesIdList}
							as="textarea"
							rows={3}
						/>
					</Form.Group>

					<ValidationError
						prefix="propertyIds"
						field="propertyIds"
						errors={state.errors}
					/>
					<Form.Group className="mb-3 d-none" controlId="uid">
						<Form.Control
							name="uid"
							value={uniqueId}
							placeholder="uid"
							type="text"
						/>
					</Form.Group>
					<ValidationError prefix="uid" field="uid" errors={state.errors} />
					<Form.Group
						className="mb-3 d-none"
						controlId="Number of workstations"
					>
						<Form.Control
							name="Number of workstations"
							value={numberOfPeople.max}
							placeholder="Number of workstations"
							type="text"
						/>
					</Form.Group>
					<ValidationError
						prefix="Number of workstations"
						field="Number of workstations"
						errors={state.errors}
					/>
					<Form.Group className="mb-3 d-none" controlId="Budget">
						<Form.Control
							name="Budget"
							value={budgetPerDesk}
							placeholder="Budget"
							type="text"
						/>
					</Form.Group>
					<ValidationError
						prefix="Budget"
						field="Budget"
						errors={state.errors}
					/>
					<Form.Group className="mb-3 d-none" controlId="Location">
						<Form.Control
							name="Location"
							value={selectedLocation}
							placeholder="Location"
							type="text"
						/>
					</Form.Group>
					<ValidationError
						prefix="Location"
						field="Location"
						errors={state.errors}
					/>

					{/* <div
        class="g-recaptcha"
        data-sitekey=""
      ></div> */}
					<Button
						size="small"
						className="btn btn-darker-blue text-white mt-2 px-6 rounded-pill"
						type="submit"
						id="contact-send-btn"
						disabled={state.submitting}
					>
						SUBMIT
					</Button>
				</Form>
				<div className="d-none">
					{(window.location = "/thank-you-for-your-shortlist")}
				</div>
			</div>
		);
	}
	return (
		<Form className="w-100" onSubmit={handleSubmit}>
			<h2
				className={`mb-4 fs-4 text-white ${
					currentStep === "one" ? "" : "d-none"
				}`}
			>
				Personal details
			</h2>
			<h2
				className={`mb-4 fs-4 text-white ${
					currentStep === "two" ? "" : "d-none"
				}`}
			>
				Viewing preferences
			</h2>
			<h2
				className={`mb-4 fs-4 text-white ${
					currentStep === "three" ? "" : "d-none"
				}`}
			>
				Contact details
			</h2>
			<h2
				className={`mb-4 fs-4 text-white ${
					currentStep === "four" ? "" : "d-none"
				}`}
			>
				Additional notes
			</h2>

			<Form.Group
				className={`${formGroupStyles} ${
					currentStep === "two" ? "" : "d-none"
				}`}
				controlId="Date and time"
			>
				<Form.Label className="text-white">Viewing date & time</Form.Label>
				{/* <Form.Control
					required
					name="Date and time"
					//   placeholder="Date and time"
					type="datetime-local"
					//   onFocus={(e) => (e.currentTarget.type = "datetime-local")}
					//   onBlur={(e) => (e.currentTarget.type = "text")}
					className={`${formInputStyles}`}
				/> */}
				<Datetime
					dateFormat="Do MMM YYYY"
					timeFormat="HH:mm"
					inputProps={{
						placeholder: "Date and time",
						required: true,
						name: "Date and time",
						className: `${formInputStyles} form.control w-100`,
					}}
					timeConstraints={{ minutes: { step: 30 } }}
				/>
			</Form.Group>
			<ValidationError
				prefix="Date and time"
				field="Date and time"
				errors={state.errors}
			/>

			<Form.Group
				className={`${formGroupStyles} ${
					currentStep === "one" ? "" : "d-none"
				}`}
				controlId="Name"
			>
				<Form.Label className="text-white">Name</Form.Label>
				<Form.Control
					required
					name="Name"
					placeholder="John Smith"
					type="text"
					className={`${formInputStyles}`}
				/>
			</Form.Group>
			<ValidationError prefix="Name" field="Name" errors={state.errors} />

			<Form.Group
				className={`${formGroupStyles} ${
					currentStep === "one" ? "" : "d-none"
				}`}
				controlId="Company"
			>
				<Form.Label className="text-white">Company</Form.Label>

				<Form.Control
					required
					name="Company"
					placeholder="Smith Co."
					type="text"
					className={`${formInputStyles}`}
				/>
			</Form.Group>
			<ValidationError prefix="Company" field="Company" errors={state.errors} />

			<Form.Group
				className={`${formGroupStyles} ${
					currentStep === "two" ? "" : "d-none"
				}`}
				controlId="Move in date"
			>
				<Form.Label className="text-white">Move in date</Form.Label>
				<Datetime
					dateFormat="Do MMM YYYY"
					timeFormat={false}
					inputProps={{
						placeholder: "Move in date",
						required: true,
						name: "Move in date",
						className: `${formInputStyles} form.control w-100`,
					}}
				/>
			</Form.Group>
			<ValidationError
				prefix="Move in date"
				field="Move in date"
				errors={state.errors}
			/>

			<Form.Group
				className={`${formGroupStyles} ${
					currentStep === "three" ? "" : "d-none"
				}`}
				controlId="Telephone number"
			>
				<Form.Label className="text-white">Phone</Form.Label>

				<Form.Control
					required
					type="tel"
					name="Telephone number"
					placeholder="0212 345 6789"
					className={`${formInputStyles}`}
				/>
			</Form.Group>

			<ValidationError
				prefix="Telephone number"
				field="Telephone number"
				errors={state.errors}
			/>
			<Form.Group
				className={`${formGroupStyles} ${
					currentStep === "three" ? "" : "d-none"
				}`}
				controlId="E-mail address"
			>
				<Form.Label className="text-white">Email</Form.Label>

				<Form.Control
					required
					name="E-mail address"
					type="E-mail address"
					placeholder="jsmith@smithco.co.uk"
					className={`${formInputStyles}`}
				/>
			</Form.Group>
			<ValidationError
				prefix="E-mail address"
				field="E-mail address"
				errors={state.errors}
			/>
			<Form.Group
				className={`${formGroupStyles} ${
					currentStep === "four" ? "" : "d-none"
				}`}
				controlId="Notes"
			>
				<Form.Control
					required
					placeholder="Notes"
					name="Notes"
					//   placeholder="Notes"
					as="textarea"
					rows={8}
					className={`${formInputStyles}`}
				/>
			</Form.Group>
			<ValidationError prefix="Notes" field="Notes" errors={state.errors} />

			<Form.Group className="mb-3 d-none" controlId="properties">
				<Form.Control
					name="properties"
					placeholder="properties"
					value={proptiesList}
					as="textarea"
					rows={3}
				/>
			</Form.Group>

			<ValidationError
				prefix="properties"
				field="properties"
				errors={state.errors}
			/>
			<Form.Group className="mb-3 d-none " controlId="providers">
				<Form.Control
					name="providers"
					placeholder="providers"
					value={providersList}
					as="textarea"
					rows={3}
				/>
			</Form.Group>

			<ValidationError
				prefix="providers"
				field="providers"
				errors={state.errors}
			/>
			<Form.Group className="mb-3 d-none" controlId="propertyIds">
				<Form.Control
					name="propertyIds"
					placeholder="propertyIds"
					value={proptiesIdList}
					as="textarea"
					rows={3}
				/>
			</Form.Group>

			<ValidationError
				prefix="propertyIds"
				field="propertyIds"
				errors={state.errors}
			/>
			<Form.Group className="mb-3 d-none" controlId="uid">
				<Form.Control
					name="uid"
					value={uniqueId}
					placeholder="uid"
					type="text"
				/>
			</Form.Group>
			<ValidationError prefix="uid" field="uid" errors={state.errors} />
			<Form.Group className="mb-3 d-none" controlId="Number of workstations">
				<Form.Control
					name="Number of workstations"
					value={
						!numberOfPeople.reset
							? numberOfPeople.min + " " + "-" + " " + numberOfPeople.max
							: "Capacity not set by customer"
					}
					placeholder="Number of workstations"
					type="text"
				/>
			</Form.Group>
			<ValidationError
				prefix="Number of workstations"
				field="Number of workstations"
				errors={state.errors}
			/>
			<Form.Group className="mb-3 d-none" controlId="Budget">
				<Form.Control
					name="Budget"
					value={
						budgetPerDesk !== budgetMinMax
							? !budgetPerDesk.perDesk
								? "£" +
								  budgetPerDesk.min +
								  " " +
								  "-" +
								  " " +
								  "£" +
								  budgetPerDesk.max +
								  " pcm"
								: "£" +
								  budgetPerDesk.minPerDesk +
								  " " +
								  "-" +
								  " " +
								  "£" +
								  budgetPerDesk.maxPerDesk +
								  " Per Desk"
							: "Budget not set by customer"
					}
					placeholder="Budget"
					type="text"
				/>
			</Form.Group>
			<ValidationError prefix="Budget" field="Budget" errors={state.errors} />
			<Form.Group className="mb-3 d-none" controlId="Location">
				<Form.Control
					name="Location"
					value={selectedLocation}
					placeholder="Location"
					type="text"
				/>
			</Form.Group>
			<ValidationError
				prefix="Location"
				field="Location"
				errors={state.errors}
			/>

			{/* <div
        class="g-recaptcha"
        data-sitekey=""
      ></div> */}
			<Button
				style={{ borderRadius: "0px" }}
				variant="outline-white"
				className={`btn w-100 w-md-auto w-lg-100  mt-2 py-3 px-6 ${
					currentStep === "four" ? "" : "d-none"
				}`}
				type="submit"
				id="contact-send-btn"
				disabled={state.submitting}
			>
				Submit
			</Button>
			<Button
				onClick={() => setCurrentStep("two")}
				style={{ borderRadius: "0px" }}
				variant="outline-white"
				className={`btn w-100 w-md-auto w-lg-100  mt-2 py-3 px-6 ${
					currentStep === "one" ? "" : "d-none"
				}`}
			>
				Next
			</Button>
			<Button
				onClick={() => setCurrentStep("three")}
				style={{ borderRadius: "0px" }}
				variant="outline-white"
				className={`btn w-100 w-md-auto w-lg-100  mt-2 py-3 px-6 ${
					currentStep === "two" ? "" : "d-none"
				}`}
			>
				Next
			</Button>
			<Button
				onClick={() => setCurrentStep("four")}
				style={{ borderRadius: "0px" }}
				variant="outline-white"
				className={`btn w-100 w-md-auto w-lg-100  mt-2 py-3 px-6 ${
					currentStep === "three" ? "" : "d-none"
				}`}
			>
				Next
			</Button>
		</Form>
	);
}
export default ContactForm;

import React from "react";
import Layout from "../components/layout";
import ContactForm from "../components/contactForm";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import { Helmet } from "react-helmet";
import { useStaticQuery, graphql, Link } from "gatsby";
import CartContext from "../context/CartContext";
import ShortlistPropertyCard from "../components/shorlist-property-card";
import PreferToTalk from "../components/prefer-to-talk";
import { Button } from "react-bootstrap";

const ShortlistPage = () => {
	const data = useStaticQuery(graphql`
		query {
			site {
				siteMetadata {
					siteUrl
				}
			}
			heroInterior: wpMediaItem(title: { eq: "hero-interior" }) {
				altText
				localFile {
					publicURL
					childImageSharp {
						original {
							height
							width
						}
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 30
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
		}
	`);

	const siteUrl = data.site.siteMetadata.siteUrl;

	const { cart } = React.useContext(CartContext);

	const breadcrumb = {
		"@context": "http://schema.org",
		"@type": "BreadcrumbList",
		itemListElement: [
			{
				"@type": "ListItem",
				position: "1",
				name: "Home",
				item: {
					url: `${siteUrl}`,
					id: `${siteUrl}`,
				},
			},
			{
				"@type": "ListItem",
				position: "2",
				name: "Shortlist",
				item: {
					url: `${siteUrl}/shortlist`,
					id: `${siteUrl}/shortlist`,
				},
			},
		],
	};

	return (
		<Layout shortlistPage>
			<Helmet>
				<script
					src="https://www.google.com/recaptcha/api.js"
					async
					defer
				></script>
				<script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
			</Helmet>
			<GatsbySeo
				title="Shortlist | Your Desk Workplace Solutions"
				description="Find your perfect office solution with the UK's most trusted brokerage."
				language="en"
				openGraph={{
					type: "website",
					url: `${siteUrl}/shortlist`,
					title: "Shortlist | Your Desk Workplace Solutions",
					description:
						"Find your perfect office solution with the UK's most trusted brokerage.",
					images: [
						{
							url: `${data.heroInterior?.localFile.publicURL}`,
							width: `${data.heroInterior?.localFile.childImageSharp.original.width}`,
							height: `${data.heroInterior?.localFile.childImageSharp.original.height}`,
							alt: `${data.heroInterior?.altText}`,
						},
					],
				}}
			/>
			<div style={{ overflow: "hidden" }}>
				<section className="bg-primary  py-4  mb-4">
					<Container>
						<Row>
							<Col className="text-start text-white">
								<h1>My Shortlist</h1>
								<p className="text-white fw-bold">ARRANGE YOUR VIEWINGS</p>
							</Col>
						</Row>
					</Container>
				</section>
				<section className="pb-0 pb-xl-6">
					<Container>
						<Row className="g-xl-5 g-2">
							{cart?.length < 1 && (
								<Col className="pb-5 pb-lg-none" lg={6}>
									<p className="fs-3">
										You currently have no shortlisted properties.
									</p>
									<Button
										style={{ borderRadius: "200px" }}
										as={Link}
										to="/properties"
										variant="dark-blue w-100 w-md-auto px-4"
									>
										View offices
									</Button>
								</Col>
							)}
							{cart?.length > 0 && (
								<Col xl={8} className="pb-2">
									{cart?.map((property, i) => {
										return (
											<ShortlistPropertyCard property={property} index={i} />
										);
									})}
								</Col>
							)}

							<Col
								xs={{ order: "first" }}
								xl={{ span: 4, order: "last" }}
								className="pb-0"
							>
								{/* <div dangerouslySetInnerHTML={{ __html: html }} /> */}
								{cart?.length > 0 && (
									<div className="p-3  bg-primary ">
										<ContactForm />
									</div>
								)}
								<div className="py-4 px-xl-6 px-2">
									<PreferToTalk />
								</div>
							</Col>
						</Row>
					</Container>
				</section>
			</div>
		</Layout>
	);
};

export default ShortlistPage;
